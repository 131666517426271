@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.ant-table table { font-size: 0.75rem; }
.ant-layout {background-color: white;}

/* table error rows */
.table-row-error-truncated {
  background-color: #fffbc4;
}

.table-row-error-no-logout {
  background-color: #ffd2c9;
}

.table-row-error-duplicate {
  background-color: #ffdfc4;
}

/* disable hover*/
.ant-table-tbody>tr.table-row-error-truncated:hover>td,
.ant-table-tbody>tr.table-row-error-no-logout:hover>td,
.ant-table-tbody>tr.table-row-error-duplicate:hover>td {
  background: unset; 
}

/* custom scrollbar css */
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}


/* text size for antd tables within smallTextTable tag */
.smallTextTable .ant-table table {
  font-size: 0.65rem
}

/* text align for the legends used by apex charts */
.apexcharts-legend {
  text-align: left;
}
/* center align for all apex charts */
.apexcharts-canvas {
  display: inline-block;
}

.apexcharts-canvas {
}
